import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Header from "../components/header";

const LegalAdvice = () => (
  <Layout>
    <Seo title="Nosotros" />
    <Header />
    <main className="aviso-legal">
      <h1>Aviso legal</h1>
      <p>
        La web <strong>CENFARTE, S.A.</strong> tiene por objeto facilitar el
        conocimiento por el público en general de CENFARTE, de las actividades
        que realiza y de los servicios que presta.
      </p>
      <p>
        <strong>CENFARTE, S.A.</strong> se reserva la facultad de efectuar, en
        cualquier momento y sin previo aviso, modificaciones de la información
        contenida en su Web o en la configuración y presentación de ésta.
      </p>
      <p>
        Al acceder a esta Web se acepta que el <strong>CENFARTE, S.A.</strong>{" "}
        no será responsable de consecuencia alguna, daño o perjuicio que se
        deriven de dicho acceso o uso de la información de dicha Web o por el
        acceso a otras materias en Internet a través de las conexiones con esta
        Web.
      </p>
      <p>
        El Copyright del material contenido en esta web es propiedad del{" "}
        <strong>CENFARTE, S.A.</strong>. El acceso a él no implica licencia para
        su reproducción y/ o distribución y no está permitido hacerlo sin el
        previo consentimiento del <strong>CENFARTE, S.A.</strong>.
      </p>
      <p>
        Las eventuales referencias que se hagan en la web del{" "}
        <strong>CENFARTE, S.A.</strong> a cualquier producto, servicio, proceso,
        enlace, hipertexto o cualquier otra información utilizando la marca, el
        nombre comercial o el fabricante o suministrador, etc. que sean de
        titularidad de terceros no constituye o implica respaldo, patrocinio o
        recomendación por parte del <strong>CENFARTE, S.A.</strong>
      </p>
      <h2>Términos de uso</h2>
      <p>
        La información contenida en estas páginas web es la vigente en la fecha
        de su última actualización
      </p>
      <p>
        El usuario puede confirmar la fecha de la actualización de cualquier
        información en el 942.33.40.70 o en{" "}
        <a href="mailto:informatica@cenfarte.com. Esta">
          informatica@cenfarte.com
        </a>
        .<a href="mailto:informatica@cenfarte.com. Esta"> </a>Esta dirección de
        correo electrónico está protegida contra los robots de spam, necesitas
        tener Javascript activado para poder verla.&nbsp;
      </p>
      <p>
        <strong>CENFARTE, S.A.</strong> rechaza la responsabilidad que se derive
        de la mala utilización de los contenidos y se reserva el derecho a
        actualizarlos cuando quiera, a eliminarlos, limitarlos o impedir el
        acceso a ellos, de manera temporal o definitiva.
      </p>
      <p>
        <strong>CENFARTE, S.A.</strong> no se hace responsable de los posibles
        errores de seguridad que se pudieran producir por el hecho de utilizar
        ordenadores infectados con Virus informáticos o de las consecuencias que
        se pudieran derivar del mal funcionamiento del navegador o por el uso de
        versiones no actualizadas de este.
      </p>
      <p>
        La reproducción, copia, uso, distribución, comercialización,
        comunicación pública o cualquier otra actividad que se pueda hacer con
        la información contenida en estas páginas web y que se realice sin
        autorización de <strong>CENFARTE, S.A.</strong>, es una infracción que
        castiga la legislación vigente.
      </p>
      <p>La impresión de documentos no tendrá validez legal.</p>
      <h2>Condiciones Ofertas WEB</h2>
      <p>
        Las ofertas son válidas únicamente para pedidos realizados desde la
        página web.
      </p>
      <p>
        <strong>CENFARTE, S.A.</strong> se reserva el derecho de modificar o
        anular las ofertas sin previo aviso.
      </p>
      <p>Las ofertas quedan sujetas a disponibilidad de género o de regalos.</p>
      <p>El tiempo máximo de envío de las ofertas será de 48 horas.</p>
      <h2>Protección de Datos</h2>
      <p>
        En <strong>CENFARTE, S.A.</strong> estamos especialmente preocupados por
        la seguridad y por garantizar la confidencialidad de los datos aportados
        por nuestros clientes.
      </p>
      <p>
        En el momento que un cliente se registra en la Web de{" "}
        <strong>CENFARTE, S.A.</strong> sus datos personales, domiciliarios, y
        los relativos a sus compras y formas de pago, son incorporados a nuestra
        base de datos para garantizar su funcionamiento.
      </p>
      <p>
        El cliente autoriza expresamente a <strong>CENFARTE, S.A.</strong> para
        que los datos de este registro y cuantos se obtengan para el desarrollo
        de las relaciones contractuales entre ambas partes puedan ser
        facilitadas a las empresas del grupo CENFARTE para que dichas empresas
        puedan efectuar estudios de mercado y ofrecerle al cliente productos y
        servicios que puedan resultar de su interés.
      </p>
      <h2>Ley de Protección de Datos</h2>
      <p>
        En cumplimiento de lo dispuesto en la{" "}
        <strong>Ley Orgánica 15/1999 de 13 de Diciembre</strong>, de Protección
        de Datos de Carácter Personal, le informamos de que:
      </p>{" "}
      <ul>
        <li>
          En su calidad de propietario, el responsable de este fichero es{" "}
          <strong>CENFARTE, S.A.</strong> con CIF A-39000849 y domicilio social
          en calle Río Pisueña s/n Polígono Industrial de Candina, Santander.
        </li>
        <li>
          <strong>CENFARTE, S.A.</strong> garantiza la confidencialidad de todos
          los datos facilitados por sus clientes. Además de los mínimos
          establecidos por la legislación, la recogida y tratamiento de los
          datos se efectúan bajo niveles de seguridad que impiden la pérdida o
          manipulación de los datos.
        </li>
        <li>
          De acuerdo con la legislación vigente, todos los clientes de{" "}
          <strong>CENFARTE, S.A.</strong> tienen el derecho de acceso,
          rectificación, cancelación y oposición de sus datos. Además, en
          cualquier momento, el cliente puede manifestar su deseo de NO recibir
          ningún tipo de publicidad.Para ejercitar estos derechos, los clientes
          pueden dirigirse por correo postal a la dirección: calle Río Pisueña
          s/n Polígono Industrial de Candina, 39011 Santander (CANTABRIA). o
          llamar al teléfono 942.33.40.70, o enviar un correo electrónico a la
          dirección{" "}
          <a href="mailto:informatica@cenfarte.com. Esta">
            informatica@cenfarte.com
          </a>
          . Esta dirección de correo electrónico está protegida contra los
          robots de spam, necesitas tener Javascript activado para poder verla .
        </li>

        <li>
          Para el mejor cumplimiento de sus servicios,{" "}
          <strong>CENFARTE, S.A.</strong> se encuentra obligado a facilitar
          determinados datos de sus clientes (nombre, dirección, teléfono, etc.)
          a otras empresas que colaboran con la prestación del servicio
          (transportistas, instaladores, entidades financieras, etc.). En todos
          los casos, los datos que se facilitan son los estrictamente necesarios
          para la actividad concreta que se vaya a realizar.
        </li>
      </ul>
    </main>
  </Layout>
);

export default LegalAdvice;
